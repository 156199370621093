import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tableActions } from '../../../_actions';
import { page } from '../../../_constants';
import { Role } from '../../../_helpers/user.perms';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from '../../../Components/Layout/Title';
import { useSelector } from 'react-redux';
import { apiService, customerService, journeyService } from '../../../_services';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';

import CustomerSelector from '../../../Components/EditingComponents/CustomerSelector';
import { formatDate } from 'src/_helpers/utility';

function getOptions(role) {
    switch (role) {
        default:
            return [page.Appointments, page.ShowAndSellCalls];
        case Role.JourneyCreator:
            return [page.Appointments, page.ShowAndSellCalls]
    }
}

export default function DashboardHeader(props) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role } = props;
    const options = getOptions(role);
    const user = useSelector(state => state.authentication.user);
    const [salesCard, setSalesCard] = useState()
    const [creatingCall, setCreatingCall] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [customer, setCustomer] = useState()

    function getSettingData(selectedPage) {
        switch (selectedPage) {
            case page.Appointments: return { title: t("dashboard.new-appointments"), subtext: t("dashboard.use-this-shortcut-to-create-new-appointment"), icon: "far fa-plus" };
            case page.ShowAndSellCalls: return { title: t("dashboard.new-show-and-sell"), subtext: t("dashboard.use-this-shortcut-to-start-a-show-and-sell"), icon: "fal fa-webcam" };
        }
    }

    function routeToModal(route) {
        dispatch(tableActions.changeTableOption(route));
        if (route == page.Appointments) {
            return navigate(`/appointments?create=true`);
        }
        if (route == page.ShowAndSellCalls) {
            setShowModal(true)
        }
    }

    function QuickCard({ target }) {
        const data = getSettingData(target);

        return <li className="shortcut-card" onClick={() => routeToModal(target)}>
            <span className="icon">
                <i className={data.icon} />
            </span>
            <div>
                <label className="main-text">
                    {data.title}
                </label>
                <p className="bottom-text">
                    {data.subtext}
                </p>
            </div>
        </li>
    }

    async function getSalesCard() {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString();
        const startOfPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1).toISOString();
        const endOfPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0).toISOString();

        console.log("Start of Current Month:", startOfMonth);
        console.log("End of Current Month:", endOfMonth);
        console.log("Start of Previous Month:", startOfPrevMonth);
        console.log("End of Previous Month:", endOfPrevMonth);

        const [currentMonthCalls, prevMonthCalls, currentMonthOrders, prevMonthOrders] = await Promise.all([
            apiService.getItems("video-calls", {
                include: ['participants'],
                searchFields: ['start>', 'end<'],
                searchValues: [startOfMonth, endOfMonth]
            }),
            apiService.getItems("video-calls", {
                include: ['participants'],
                searchFields: ['start>', 'end<'],
                searchValues: [startOfPrevMonth, endOfPrevMonth]
            }),
            apiService.getItems("orders", {
                searchFields: ['created>'],
                searchValues: [startOfMonth]
            }),
            apiService.getItems("orders", {
                searchFields: ['created>'],
                searchValues: [startOfPrevMonth]
            }),
        ]);

        // Debugging the responses
        console.log("Current Month Calls:", currentMonthCalls);
        console.log("Previous Month Calls:", prevMonthCalls);
        console.log("Current Month Orders:", currentMonthOrders);
        console.log("Previous Month Orders:", prevMonthOrders);

        const currentMonthCallsCount = currentMonthCalls.items.length;
        const prevMonthCallsCount = prevMonthCalls.items.length;

        const { calls, sales, orders } = getSalesCardData({
            currentMonthOrders,
            prevMonthOrders,
            currentMonthCalls: { count: currentMonthCallsCount },
            prevMonthCalls: { count: prevMonthCallsCount }
        });

        // Check if calls, sales, and orders are correctly calculated
        console.log("Sales Card Data:", { calls, sales, orders });

        setSalesCard({ calls, sales, orders });
    }

    function getSalesCardData({ currentMonthOrders, prevMonthOrders, currentMonthCalls, prevMonthCalls }) {
        // Ensure data is available
        if (!currentMonthOrders || !prevMonthOrders || !currentMonthCalls || !prevMonthCalls) {
            return {
                calls: { total: 0, increased: false, difference: 0 },
                orders: { total: 0, increased: false, difference: 0 },
                sales: { total: 0, increased: false, difference: 0 }
            };
        }

        // Calculate total sales for current and previous month based on orders
        const totalSales = currentMonthOrders.items.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
        const totalSalesPrevMonth = prevMonthOrders.items.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);

        const saleDifference = totalSales - totalSalesPrevMonth;
        // Calculate the difference in the number of orders and calls
        const orderDifference = currentMonthOrders.count - prevMonthOrders.count;
        const callDifference = currentMonthCalls.count - prevMonthCalls.count;

        return {
            calls: {
                total: currentMonthCalls.count || 0,
                increased: currentMonthCalls.count > prevMonthCalls.count,
                difference: Math.abs(callDifference) || 0
            },
            orders: {
                total: currentMonthOrders.count || 0,
                increased: currentMonthOrders.count > prevMonthOrders.count,
                difference: Math.abs(orderDifference) || 0
            },
            sales: {
                total: totalSales.toFixed(2) || 0,
                increased: totalSales > totalSalesPrevMonth,
                difference: saleDifference !== 0 ? `$${Math.abs(saleDifference).toFixed(2)}` : '$0.00'
            },
        };
    }


    useEffect(() => {
        getSalesCard()
    }, [])

    function getDifference(data, label) {
        return data?.difference ? <div className={`dashboard-sales-card-info-extra${data?.increased ? " up" : " down"}`}>
            {data?.increased ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down"></i>}
            {` ${data?.difference}`} {label} {data?.increased ? " Increased" : " Decreased"}
        </div> : <div className={`dashboard-sales-card-info-extra`}>{t("dashboard.no", { label })}</div>
    }

    async function startShowAndSellCall(id) {
        setCreatingCall(true)
        try {
            const customer = await customerService.getById(id)
            setCustomer(customer)
            const name = `${customer.name} - ${formatDate(new Date(), { year: 'numeric', month: 'short', day: 'numeric' })}`;
            const roomName = `${customer.name} - ${formatDate(new Date(), { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}`;
            const newJourney = {
                name: name,
                type: 7,
                roomName: roomName
            };
            const journey = await journeyService.addJourney(newJourney)
            const accessCode = await journeyService.requestAccessCode({ customerId: id }, journey.id);
            await journeyService.emailAccessCode(journey.id, accessCode.id);

            const videoCall = {
                roomName: roomName,
                journeyId: journey.id,
                roomType: 2,
            }
            const result = await apiService.addItem('video-calls', videoCall);
            setShowModal(false);
            navigate(`/show-and-sell-call/${result.id}`);
        } catch (error) {
            console.log(error)
        }
        setCreatingCall(false)
    }

    return (<>
        <Title>{t("dashboard.welcome-back", { user: user.name })}</Title>
        <div className='dashboard-sales-card-info-wrap'>
            <div className='dashboard-sales-card-info'>
                <div className='dashboard-sales-card-info-title'>{t("dashboard.total-sales")} <i className="fal fa-chart-line"></i></div>
                <div className='dashboard-sales-card-info-value'>${salesCard?.sales?.total}</div>
                {getDifference(salesCard?.sales, "Sales")}
            </div>
            <div className='dashboard-sales-card-info'>
                <div className='dashboard-sales-card-info-title'>{t("dashboard.total-orders")} <i className="fal fa-shopping-cart"></i></div>
                <div className='dashboard-sales-card-info-value'>{salesCard?.orders.total}</div>
                {getDifference(salesCard?.orders, "Orders")}
            </div>
            <div className='dashboard-sales-card-info'>
                <div className='dashboard-sales-card-info-title'>{t("dashboard.total-calls")} <i className="fal fa-phone-alt"></i></div>
                <div className='dashboard-sales-card-info-value'>{salesCard?.calls?.total}</div>
                {getDifference(salesCard?.calls, "Calls")}
            </div>
        </div>
        <ul className="shortcut-card-wrap">
            {options.map((o, i) => <QuickCard key={o} target={o} index={i} />)}
        </ul>

        <Modal show={showModal} className='modal-start-show-and-sell'>
            <ModalHeader><h4><strong>{t("modals.select-customer-to-start-show-and-sell-call")}</strong></h4></ModalHeader>
            <ModalBody>
                {!creatingCall && <CustomerSelector important={false} customerSelected={startShowAndSellCall} />}
                {creatingCall && <div className="container text-center mt-5">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t("modals.please-wait-while-we-create-a-show-and-sell-call-with", { name: customer?.name })}</span>
                    </div>
                    <h5>{t("modals.please-wait-while-we-create-a-show-and-sell-call-with", { name: customer?.name })}</h5>
                </div>}
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger" disabled={creatingCall} onClick={() => setShowModal(false)}>{t("modals.cancel")}</button>
            </ModalFooter>
        </Modal>
    </>
    )
}
