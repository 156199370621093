import React from 'react'
import { useDispatch } from 'react-redux';
import { LoginModalActions } from '../../../_actions/loginModal.actions';

export default function LoginErrorModal() {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(LoginModalActions.closeModal())
    }

    return (
        <div className="confirmation-modal login">
            <div className="confirmation-modal-header">Please try again...</div>
            <div className="confirmation-message">
                The email and password you entered did not match our records. Please double-check and try again.
            </div>
            <div className="modal-footer">
                <div className="confirmation-modal-buttons">
                    <button onClick={handleClose} className="btn btn-primary w-100">Close</button>
                </div>
            </div>
        </div>
    )
}