import { ApiOptions, apiService } from "./api.service"

export const accountService = {
    getAccount,
    topUp,
    verifyTransaction,
    getTransactions,
    getPublicKey,
    updateAccount
}
function getAccount(id: number, include: string[]) {
    return apiService.getItem('account', id, { include })
}
function topUp(amount: number) {
    return apiService.addItem('account/topup', amount)
}
function getPublicKey() {
    return apiService.getItems(`account/publicKey`)
}
function getTransactions(accountId: number, page: number, count: number, pending: boolean) {
    var options: ApiOptions = { searchField: "accountId", page: page, pageSize: count, searchValue: accountId, sortBy: "timeStamp", sortDirection: "descending" };
    if (pending) {
        options.searchField = undefined;
        options.searchFields = ['accountId', 'status'];
        options.searchValue = undefined;
        options.searchValues = [accountId, '0']
    }
    return apiService.getItems(`transaction`, options);
}

function verifyTransaction(id: number) {
    return apiService.getItem(`transaction/verify`, id)
}

function updateAccount(account: any) {
    return apiService.updateItem('account', account);
}