import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import '../../Styles/_components/_login.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function SignupForm() {
    const { t } = useTranslation("common");
    const auth = useSelector(state => state.authentication);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
    });
    const { name, email } = inputs;
    const [submitted, setSubmitted] = useState(false);

    function signup(e) {
        e.preventDefault();
        setSubmitted(true);

        if (name && email) {
            dispatch(userActions.addExternalUser({ name, email }, navigate));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    return (
        <div className="login-form">
            <div className="login-logo">
                <div className='welcome'>{t("signup.signup-title")}</div>
                <div className='welcome-msg'>{t("signup.signup-subtitle")}</div>
            </div>
            <form name="form" onSubmit={signup}>
                <div className="form-group">
                    <label>{t("signup.admin-name")}</label>
                    <input
                        data-testid="name"
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        className={'form-control' + (submitted && !name ? ' is-invalid' : '')}
                    />
                </div>
                <div className="form-group">
                    <label>{t("signup.email")}</label>
                    <input
                        data-testid="email"
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className={'form-control' + (submitted && !email ? ' is-invalid' : '')}
                    />
                </div>
                <button data-testid="signup" className="btn btn-primary">
                    {(auth && auth.signingUp) && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    {t("signup.create-account")}
                </button>
            </form>
        </div>
    );
}
